import React, { useEffect, useState } from 'react'
import './style.css'
import { Avatar, Button, Input, Switch } from 'antd'
import { EditOutlined, PlusOutlined, UserOutlined } from '@ant-design/icons'
import {fetchData, postData} from '../../../services/HomeService';
import PaywallCard from '../../common/PaywallCard'
import RequestNudgeComponent from "../../nudge/RequestNudgeComponent";
import _ from 'lodash'
import { showErrorMessage, showSuccessMessage } from '../../../utils';
import { useSelector } from 'react-redux';
import { BASE_IMG } from '../../../constants';

export default function VendorSubscriptionPlans () {
    const [plans, setPlans] = useState([]);
    const [isAnnual, setIsAnnual] = useState(false);

    const [isSubmitModalVisible, setIsSubmitModalVisible] = useState(false);
    const [submitModalRequestData, setSubmitModalRequestData] = useState('vendor-subscription');

    const userData = useSelector((state) => state.user); 

    const handleNudgeSubmit = async (data, onClose) => {
      const { name = '', orgName, email = '' } = userData || {};
      const company = orgName || 'NA';
      const requestData = { name, company, email, comment: data };
  
      try {
        const response = await postData("/notifications/contact-us", requestData);
        if (response.success) {
          showSuccessMessage("Request successfully submitted!!");
        } else {
          showErrorMessage(response.message);
        }
      } catch (error) {
        showErrorMessage(error.message);
      } finally {
        onClose();
      }
    };

    const handleSubmit = (selectedPlan, selectedKeys=[]) => {
      const data = JSON.stringify({
        name: selectedPlan.name,
        mode: selectedPlan.mode,
        type: selectedPlan.type,
        selectedOptions: _.map(selectedPlan.options.filter((option) => (selectedPlan.topPlan || (selectedKeys && selectedKeys.includes(option.key)))), 'description'),
      })
      setIsSubmitModalVisible(true);
      setSubmitModalRequestData(data)
    };
  
    const closeSubmitPlanModal = () => {
      setIsSubmitModalVisible(false);
    };

    useEffect(() => {
        const fetchItems = async () => {
            const response = await fetchData(`/subscriptions/pricing-vendor?mode=${isAnnual ? "y" : "m"}`);
            setPlans(response.data); 
        };
        fetchItems();
    }, [isAnnual]);

    const basicPlan = plans.find((item) => !item.topPlan);
    const premiumPlan = plans.find((item) => item.topPlan);
  
    if (!basicPlan || !premiumPlan) {
      return null;
    }

  return (
    <main className='Profile'>
        <div className='Profile_container'>
            <section className='Profile_firstContainer'>
                <div className='Profile_firstTopContainer'>
                <div className='Profile_ImageContainer'>
                    <Avatar  icon={<UserOutlined />}  />
                    <div className='Profile_editIcon' >
                        <EditOutlined/>
                    </div>
                    <Button> <EditOutlined />Change Cover</Button>
                    </div>
                </div>
                <div className='Profile_firstBottomContainer'>
                    <div className='Profile_nameContainer'>
                        <span>Name & basic info</span>
                        <PlusOutlined />
                    </div>
                </div>
            </section>
            <section className='Profile_secondContainer'>
                <img src={`${BASE_IMG}/paywall_images/Subscription.svg`} alt=''/>
                <div className='Profile_coupon'>
                    <div className='Profile_couponTitle'>
                        <h4>Unlock all profile features</h4>
                        <span>Create your full profile and start getting noticed</span>
                    </div>
                    <Input placeholder='Coupon code' size='large' addonAfter={<PlusOutlined/>}/>
                </div>
                <div className='Profile_planContainer'>
                <div className="Pw_switchGroup">
            <span>Choose your plan</span>
            <div className="Pw_switch">
              <span style={{ color: isAnnual ? "#8C8C8C" : "#434343" }}>
                Monthly
              </span>
              <Switch
                checked={isAnnual}
                onChange={() => setIsAnnual(!isAnnual)}
              />
              <span style={{ color: isAnnual ? "#434343" : "#8C8C8C" }}>
                Annual
              </span>
            </div>
          </div>
                  <div className='Profile_planCards'>
                    <PaywallCard
                      plan={basicPlan}
                      isAnnual={isAnnual}
                      totalCost={basicPlan.totalCost}
                      handleUnlockButtonClick={handleSubmit}
                      // handleCheckboxChange={handleCheckboxChange}
                    />
                    <PaywallCard
                      plan={premiumPlan}
                      isAnnual={isAnnual}
                      totalCost={premiumPlan.totalCost}
                      handleUnlockButtonClick={handleSubmit}
                      // handleCheckboxChange={handleCheckboxChange}
                    />
                    </div>  
                </div>
                <RequestNudgeComponent
                  type={'waitlist'}
                  data={submitModalRequestData}
                  isVisible={isSubmitModalVisible}
                  onClose={closeSubmitPlanModal} 
                  handleNudgeSubmit={handleNudgeSubmit}
                />
            </section>
        </div>
    </main>
  )
}