import React, { useMemo, useState } from 'react';
import { Flex, Carousel, Tag, Avatar, Image, Button, Row, Col } from 'antd';
import { InteractionOutlined, LikeOutlined, MessageOutlined, MoreOutlined, PlaySquareOutlined, ShareAltOutlined, ShoppingCartOutlined } from '@ant-design/icons';

import { getYoutubeEmbedUrl } from '../../utils';
import styles from './style.module.css';

export default function ProductView(props) {
    const { product, handleReactProduct } = props;
    const [isExpanded, setIsExpanded] = useState(true);
    const [selectedColor, setSelectedColor] = useState("NA");
    const [showVideo, setShowVideo] = useState(false);

    const playVideo = () => { setShowVideo(true) }
    const stopVideo = () => { setShowVideo(false) }

    const togglePlayVideo = () => {
        if (showVideo) stopVideo();
        else playVideo();
    }

    const toggleIsExpanded = () => { setIsExpanded(!isExpanded) }
    const handleColorSelect = (newColor) => {
        if(showVideo) togglePlayVideo();
        setSelectedColor(newColor)
    }

    const renderTag = (tag) => {
        if (tag === "economical") return <Tag color="lime">Economical</Tag>
        else if (tag === "Value for money") return <Tag color="orange">Value for Money</Tag>
        else if (tag === "Premium") return <Tag color="geekblue">Premium</Tag>
        else if (tag === "Super premium") return <Tag color="purple">Super premium</Tag>

        return (
            <Tag color="green">{tag}</Tag>
        )
    }

    const renderAttributes = ((val, unit) => {
        if (unit){
            return val.split(/[ ,]+/).filter(v => Boolean(v)).reduce((initial, current, index) => initial + (index === 1 ? " " + unit : "") +  ", " + current + " "+ unit )
        } 
        else return typeof val === 'object' ? val?.reduce((current, initial) => current + ", " + initial): val
    })

    const embedUrl = useMemo(() => {
        if (product.videos.length <= 0) return null;
        return getYoutubeEmbedUrl(product.videos[0]);
    }, [product]);

    const carouselImg = useMemo(() => {
        if (!product || !product?.images || product.images.length === 0) return [];
        if (selectedColor === "NA" || selectedColor === "Custom") {
            return product.images.filter(val => {
                if (selectedColor === "NA" && val.type === "Thumbnail") return true;
                return val?.type === selectedColor
            });
        }
        return product.images.filter(val => val?.color === selectedColor);
    }, [selectedColor, product]);

    const [allColors, showCustom] = useMemo(() => {
        if (product?.images?.length === 0) return [[], false];
        const colorSet = new Set();

        let customPresent = false;

        for (let val of product.images) {
            if (val.type === 'Custom') customPresent = true;
            if (val.type === "Variant") colorSet.add(val.color);
        }

        return [
            Array.from(colorSet),
            customPresent
        ];
    }, [product]);


    return (
        <div className={styles.productContainer}>
            <Flex
                justify='space-between'
                align='center'
            >
                <Flex
                    gap="12px"
                    align='center'
                >
                    {
                        product?.category?.image ?
                            <Image
                                src={product.category.image}
                                style={{
                                    width: '32px',
                                    height: '32px',
                                    borderRadius: '50%'
                                }}
                            />
                            :
                            <Avatar style={{ backgroundColor: '#fde3cf', color: '#f56a00' }}>
                                {product.name?.toUpperCase()}
                            </Avatar>
                    }
                    <div className={styles.categoryBox}>
                        <Flex gap="8px" align='center'>
                            <p className={styles.category}>{product?.subCategory?.label}</p>
                        </Flex>
                        <Flex gap="8px" align='center'>
                            <p className={styles.location}>{product?.category?.label}</p>
                            <span>&nbsp;</span>
                            <p className={styles.location}>
                                {
                                    product?.placesOfSupply.length && product?.placesOfSupply[0] ?
                                        `${product?.placesOfSupply[0]?.slice(0, 1).toUpperCase()}${product?.placesOfSupply[0]?.slice(1)}`
                                        :
                                        '-'
                                }
                            </p>
                        </Flex>
                    </div>
                </Flex >

                <Flex
                    align='center'
                    gap="5px"
                >
                    <div>
                        <Tag color="green">Eco Friendly</Tag>
                    </div>
                    {
                        product.segments.map((val, i) => (
                            <div key={i}>
                                {renderTag(val)}
                            </div>
                        ))
                    }
                    <Button size="small">
                        <ShoppingCartOutlined />
                    </Button>
                    <div>
                        <MoreOutlined className="ListComponent_moreIcon" />
                    </div>
                </Flex>
            </Flex >

            <Flex
                justify='space-between'
                gap="12px"
                style={{
                    marginTop: "20px"
                }}
            >
                <p className={styles.description}>
                    {
                        product.description.length > 290 ?
                            isExpanded ?
                                <>
                                    {product.description.slice(0, 290) + "... "}
                                    <span
                                        style={{ cursor: 'pointer', color: 'rgb(72, 0, 255)' }}
                                        onClick={toggleIsExpanded}
                                    >
                                        Read More
                                    </span>
                                </>
                                :
                                <>
                                    {product.description} &nbsp;
                                    <span
                                        style={{ cursor: 'pointer', color: 'rgb(72, 0, 255)' }}
                                        onClick={toggleIsExpanded}
                                    >
                                        Read Less
                                    </span>
                                </>
                            : product.description
                    }
                </p>
            </Flex>

            <div className={styles.attributes}>
                {
                    product.attributes.map((val, i) => (
                        <>
                            <p key={i}>
                                {val.key}: {renderAttributes(val.value, val.unit)}
                            </p>
                            {
                                !!(i + 1 !== product.attributes.length) && <p className={styles.dotSpan}>&nbsp;</p>
                            }
                        </>
                    ))
                }
                {
                    !!product.attributes.length && !!product.sampling && (
                        <p className={styles.dotSpan}>&nbsp;</p>
                    )
                }
                {
                    !!product.sampling && (
                        <p>{product.sampling === "free" ? "Free" : "Paid"} Sample</p>
                    )
                }
            </div>

            <div className={styles.imageBox}>
                {
                    showVideo ?
                        <iframe
                            src={embedUrl}
                            title="Video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerpolicy="strict-origin-when-cross-origin"
                            allowfullscreen
                            style={{
                                width: "100%",
                                height: "100%",
                                minHeight: "260px"
                            }}
                        />
                        :
                        <Carousel autoplay autoplaySpeed={1800}>
                            {
                                carouselImg.map((val, index) => (
                                    <div key={index} className={styles.carousel}>
                                        <img src={val.url} />
                                    </div>
                                ))
                            }
                        </Carousel>
                }

                {
                    !!embedUrl && (
                        <div className={styles.playbtnBox}>
                            <div
                                onClick={togglePlayVideo}
                                style={{
                                    color: "#fff",
                                    fontSize: "16px",
                                    cursor: 'pointer'
                                }}
                            >
                                <PlaySquareOutlined />
                            </div>
                        </div>
                    )
                }

                {
                    !!showCustom && (
                        <div className={styles.customsedBox}>
                            <div
                                style={{
                                    color: "#fff",
                                    fontSize: "16px",
                                    cursor: 'pointer'
                                }}
                                onClick={() => handleColorSelect("Custom")}
                            >
                                <InteractionOutlined />
                            </div>
                        </div>
                    )
                }

                <div className={styles.variantBox}>
                    {
                        allColors.map((color, index) => (
                            <div
                                className={styles.productVariant}
                                style={{ background: color }}
                                onClick={() => handleColorSelect(color)}
                            >
                                &nbsp;
                            </div>
                        ))
                    }
                    <div
                        className={styles.productVariantNA}
                        style={{ background: '#fff' }}
                        onClick={() => handleColorSelect('NA')}
                    >
                        <span>Default</span>
                    </div>
                </div>
            </div>

            <div className={styles.statsBox}>
                <Row>
                    <Col
                        span={8}
                        onClick={() => handleReactProduct(product)}
                        style={product.isLiked ? { color: "#4800FF" } : {}}
                    >
                        <LikeOutlined /> {product?.totalLikes || 0}
                    </Col>
                    <Col span={8}>
                        <MessageOutlined /> {product?.totalComments || 0}
                    </Col>
                    <Col span={8}>
                        <ShareAltOutlined /> {product?.totalShares || 0}
                    </Col>
                </Row>
            </div>
        </div >
    )
}
