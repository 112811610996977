import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'
import _ from 'lodash'

import Sidebar from './homeLayout';
import Navbar from '../common/Navbar';
import { fetchData } from '../../services/HomeService';
import { getIcon, showErrorMessage } from '../../utils';
import { useSelector } from "react-redux";

export default function HomeMain() {
    const [items, setItems] = useState({
        sidebar: [],
        popup: [],
        profile: [],
        company: [],
        feeds: []
    });
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    //TODO: Also check in userinfo cookies
    const userData = useSelector((state) => state.user); 
    const orgType = queryParams.get('org_type') || userData?.orgType || '';

    const step = queryParams.get('step') || '';
    const verified = queryParams.get('verified') || '';

    const fetchItems = async (type) => {
        if(!type) return  
        const resp = await fetchData(`/options/sidebar,popup,profile,company,feeds,component?orgType=${type}`);
        if (resp && !_.isEmpty(resp.data)) {
            setItems(resp.data);
        } else {
            showErrorMessage('Some error has happened!!');
            navigate('/failure');
        }
    };

    useEffect(() => {
        fetchItems(orgType);
    }, [orgType]);

    return (
        <>
            <Navbar showSettings={true} items={items.popup} getIcon={getIcon} profile={items.profile} company={items.company} />
            <Sidebar items={items.sidebar} getIcon={getIcon} orgType={orgType} verified={verified} step={step} AvatarColor={items.company} ComponentConfig={items.component} productFeeds={items.feeds} />
        </>
    );
}
