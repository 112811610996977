import { Button, Modal } from "antd";
import React, { useState } from "react";
import "./RequestNudgeComponent.css";
import {showLoader} from "../../utils";
import { BASE_IMG } from "../../constants";

const NUDGE_TYPES = {
  subscribe: {
    title: "Greetings!!",
    message: "To unlock this plan, please submit your request. We will reach out to you soon.",
    image: `${BASE_IMG}/alert_blue.svg`,
    btnCTA: "Submit",
    foreGroundColor: "#4800FF",
    loaderText: "Submitting"
  },
  waitlist: {
    title: "Greetings!!",
    message: "We are currently building this product for you. Please join the waitlist to avail amazing deals.",
    image: `${BASE_IMG}/alert_green.svg`,
    btnCTA: "Join now",
    foreGroundColor: "#008065",
    loaderText: "Joining"
  },
  warning : {
    title: "Alerts!!",
    message: "Some marked fields which you are trying to save does not pass set validation criteria. Rectify them or proceed anyways",
    image: `${BASE_IMG}/warning.svg`,
    btnCTA: "Proceed",
    foreGroundColor: "#4800FF",
    loaderText: "Submitting"
  },
  verify: {
    title: "",
    message: 'You are requesting to verify this field which is chargeable and you are agreeing to pay this as part of your billing cycle',
    image: `${BASE_IMG}/alert_blue.svg`,
    btnCTA: 'Verify',
    foreGroundColor: "#4800FF",
    loaderText: "Submitting"
  },
  deleteAttachment: {
    title: "Warnings!!",
    message: "Are you sure you want to delete this attachment? This action cannot be undone",
    image: `${BASE_IMG}/warning.svg`,
    btnCTA: "Delete",
    foreGroundColor: "#4800FF",
    loaderText: "Deleting"
  },
  removeVendor: {
    title: "Warnings!!",
    message: "Are you sure, you want to remove this Vendor? This will not appear in Vendors list", 
    image: `${BASE_IMG}/warning.svg`,
    btnCTA: "Remove",
    foreGroundColor: "#4800FF",
    loaderText: "Removing"
  },
  MultipleRemoveVendor: {
    title: "Warnings!!",
    message: "Are you sure, you want to remove these Vendors? They will not appear in Vendors list", 
    image: `${BASE_IMG}/warning.svg`,
    btnCTA: "Remove",
    foreGroundColor: "#4800FF",
    loaderText: "Removing"
  },
  removeItem: {
    title: "Warnings!!",
    message: "Are you sure, you want to remove this Item? This will not appear in Items list", 
    image: `${BASE_IMG}/warning.svg`,
    btnCTA: "Remove",
    foreGroundColor: "#4800FF",
    loaderText: "Removing"
  }
}

export default function RequestNudgeComponent({
  type,
  data,
  action,
  isVisible,
  onClose,
  handleNudgeSubmit
}) {
  const [isLoading, setIsLoading] = useState(false); 
  const modalData = NUDGE_TYPES[type]
  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      await handleNudgeSubmit(data, action);
    } finally {
      setIsLoading(false);
    }
  };
 
  return (
    <Modal
      open={isVisible}
      onCancel={onClose}
      footer={null}
      style={{ display: "flex", justifyContent: "center" ,top: '40%' }}
      className="Dashboard_modal"
    >
      <div className="sp_container">
        <div className="sp_subContainer">
          <img src={modalData.image} alt="" />
          <div className="sp_text">
            <h1>{modalData.title}</h1>
            <p>{modalData.message}</p>
          </div>
        </div>
        <Button
          style={{
            background: modalData?.foreGroundColor,
            border: `1px solid ${modalData?.foreGroundColor}`,
          }}
          onClick={handleSubmit}
        >
          {modalData.btnCTA}
        </Button>
      </div>
      {isLoading && showLoader(modalData.loaderText)} 
    </Modal>
  );
}
