import React, { useState } from "react";
import { List } from "antd";
import ListInfoComponent from "../../common/ListInfoComponent";
import { fetchData } from "../../../services/HomeService";
import ModalQuotationForm from "../vendor-quotations/ModalQuotationForm";
import ModalApprovalComponent from "./ModalApprovalComponent";
import ModalPartnerApproval from "./ModalPartnerApproval";

export default function ListApprovalsComponent({ type, vendorData, setVendorData, AvatarColor }) {
  const [quoteModal, setQuoteModal] = useState(false);
  const [partnerApprovalModal, setPartnerApprovalModal] = useState(false);

  const [approvalModal, setApprovalModal] = useState(false);
  const [allData, setAllData] = useState([]);
  const [quoteData, setQuoteData] = useState([]);
  const [actionAPI, setActionAPI] = useState(null)
  const [itemData, setItemData] = useState([])
  const [check, setCheck] = useState(null);

   const handleIconClick = (quoteID, item, type) => {
    const entityType = item.entityType || 'quotations'
    if(type && entityType === 'quotation'){
      const fetchItems = async () => {
        const queryString = `/quotations/quote/${quoteID}`;
        const data = await fetchData(queryString);
        setAllData(data.data);
        setQuoteData(data.quoteDetails);
        setQuoteModal(true)
        setActionAPI(item)
      }
      fetchItems();
    } else if (type && entityType === 'partners') {
      setPartnerApprovalModal(true)
      console.log('partner approval')
      setItemData(item)
      setCheck(type)
    }

   }

  const handleApproveQuote = async (item, type) => {
      setApprovalModal(true)
      setItemData(item)
      setCheck(type)
  }
 

  const closeApprovalForm = () => {
    setApprovalModal(false)
  }

  const closeQuotationForm = () => {
    setQuoteModal(false)
  }
  const closePartnerApprovalForm = () => {
    setPartnerApprovalModal(false)
  }
  return (
    <section className="ListComponent_container" style={{ width: "100%" }}>
      <List
        className="demo-loadmore-list"
        itemLayout="horizontal"
        dataSource={vendorData}
        renderItem={(item) => (
          <List.Item>
            <div className="ListComponent_box2">
              <ListInfoComponent
                name={item.userMeta.name}
                AvatarColor={AvatarColor}
                type={type}
                item={item}
                handleApproveQuote={handleApproveQuote}
                handleIconClick={handleIconClick}
              />
            </div>
          </List.Item>
        )}
      />
      {quoteModal && 
        <ModalQuotationForm
        isVisible={quoteModal}
        onClose={closeQuotationForm}
        quoteData={quoteData}
        data={allData}
        actionAPI={actionAPI}
        setActionAPI={setActionAPI}
        vendorData={vendorData}
        setVendorData={setVendorData}
        type={'ListApprovals'}
        />
      }
      {
        partnerApprovalModal &&
        <ModalPartnerApproval
          isVisible={partnerApprovalModal}
          onClose={closePartnerApprovalForm}
          item={itemData}
          setVendorData={setVendorData}
          vendorData={vendorData}
          type={check}
        />
      }
      {approvalModal &&
        <ModalApprovalComponent
          isVisible={approvalModal}
          onClose={closeApprovalForm}
          item={itemData}
          setVendorData={setVendorData}
          vendorData={vendorData}
          type={check}
        />
      }
    </section>
  );
}