import React, { useState } from "react";
import './FilterComponent.css'

import { Tag, Button, Select} from 'antd';
import { PlusOutlined, DownOutlined, UpOutlined } from '@ant-design/icons';
import { FEED_FILTER_OPTIONS } from "../../constants";

const { Option } = Select;

export default function FilterComponent(props) {

    const { type, categories, onCategoryChange } = props

    const [showExpandFilter, setShowExpandFilter] = useState(false);

    const toggleExpandFilter = () => {
        setShowExpandFilter(!showExpandFilter);
    }

    if (!type) return <></>
    const filterMap = FEED_FILTER_OPTIONS[type]
    if (!filterMap) return <></>

    return (
        <>
            <div className='filters'>
                <div className='filter-flex'>
                    <div>
                        <span style={{ margin: "10px" }}>{filterMap['level1']}: </span>
                    </div>
                    <div style={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        flexWrap: 'wrap',
                        flex: '1'
                    }}>
                        {/* TODO: Fix the hardcoded level1 filters- make generic */}
                        {
                            categories.size > 0 ? <Tag
                                onClick={() => onCategoryChange('all')}
                                color='transparent'
                                style={{
                                    border: 'none',
                                    cursor: 'pointer',
                                    marginBottom: '5px',
                                    color: '#000'
                                }}
                            >
                                All
                                <PlusOutlined className='filter-cross' />
                            </Tag>
                            : <Tag
                                onClick={() => onCategoryChange('reset')}
                                color='transparent'
                                style={{
                                    border: 'none',
                                    cursor: 'pointer',
                                    marginBottom: '5px',
                                    color: '#000'
                                }}
                            >
                                Reset
                                <PlusOutlined className='filter-cross2' />
                            </Tag>
                        }
                        {
                            Array.from(categories).slice(0, 7).map((val, i) => (
                                <Tag
                                    key={i}
                                    onClick={() => onCategoryChange(val)}
                                    color='transparent'
                                    style={{
                                        border: 'none',
                                        cursor: 'pointer',
                                        marginBottom: '5px',
                                        color: '#000'
                                    }}
                                >
                                    {val}
                                    <PlusOutlined className='filter-cross' />
                                </Tag>
                            ))
                        }
                        {
                            showExpandFilter && Array.from(categories).slice(7).map((val, i) => (
                                <Tag
                                    key={i}
                                    onClick={() => onCategoryChange(val)}
                                    color='transparent'
                                    style={{
                                        border: 'none',
                                        cursor: 'pointer',
                                        marginBottom: '5px',
                                        color: '#000'
                                    }}
                                >
                                    {val}
                                    <PlusOutlined className='filter-cross' />
                                </Tag>
                            ))
                        }
                    </div>
                    <Button className='filter-expand' type='link' onClick={toggleExpandFilter}>
                        Expand {showExpandFilter ? <UpOutlined /> : <DownOutlined />}
                    </Button>
                </div>
                { filterMap?.filters?.length > 0 ? <div>
                    <span style={{ margin: "10px" }}>Filters:</span>
                    {
                        filterMap?.filters.map((filter, index) => {
                            return <>
                                <span style={{ margin: "10px" }}>{filterMap[filter].cta}:</span>
                                <Select
                                    allowClear
                                    mode='multiple'
                                    value={props[filterMap[filter].value]}
                                    onChange={props[filterMap[filter].callback]}
                                    placeholder={filterMap[filter].placeholder || 'Set'}
                                    style={{ width: "200px" }}
                                >
                                    {filterMap[filter].options.map((option, index) => {
                                        return <Option value={option.value} key={'key' + index}>{option.label}</Option>
                                    })}
                                </Select>
                            </> 
                        })
                    }
                </div>: null}
            </div>
        </>
    );
}
