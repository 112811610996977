import { saveAs } from 'file-saver';
import Papa from 'papaparse';
import React from 'react'
import { message } from 'antd';
import _ from "lodash";
import moment from 'moment';
import { DashboardOutlined, CheckCircleOutlined, WarningOutlined, UserOutlined, LogoutOutlined, FileTextOutlined, ToolOutlined, SnippetsOutlined, CheckCircleFilled, ExclamationCircleFilled, CheckOutlined, CloseOutlined, LoadingOutlined, CoffeeOutlined, LikeOutlined, GiftOutlined, ProductOutlined, TeamOutlined } from '@ant-design/icons';
import { LINK_REGEX, LINK_SEPERATOR } from '../constants';

export async function downloadCSVFile(data, fileName = 'default_file.csv') {
  try {
    const csvData = convertToCSV(data);
    const blob = new Blob([csvData], { type: 'text/csv' });
    saveAs(blob, fileName);
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
}

function convertToCSV(dataKeys) {
  const csvData = Papa.unparse(dataKeys, {
    header: true,
    delimiter: ',',
  });
  return csvData;
}

export const getIcon = (iconName, color) => {
  const AntIcon = {
    DashboardOutlined,
    CheckCircleOutlined,
    WarningOutlined,
    UserOutlined,
    LogoutOutlined,
    FileTextOutlined,
    ToolOutlined,
    SnippetsOutlined,
    CheckOutlined,
    CloseOutlined,
    ExclamationCircleFilled,
    CheckCircleFilled,
    CoffeeOutlined,
    GiftOutlined,
    LikeOutlined,
    ProductOutlined,
    TeamOutlined
  }[iconName];
  return AntIcon ? <AntIcon style={{ color }} /> : null;
};

export const parseToListObj = (data) => {
  if (!data) return [];
  const parsedData = Papa.parse(data, { header: true }).data;
  const nonEmptyData = parsedData.filter(obj => Object.values(obj).some(value => value.trim() !== ''));
  return nonEmptyData;
};

export const createLinksMarkup = (text) => {
  const lines = text.split(LINK_SEPERATOR);

  let linkSectionIndex = 0;
  return lines.map((line, index) => {
    const parts = line.split(LINK_REGEX);
    return (
      <div key={index}>
        {parts.map((part, i) => (
          <React.Fragment key={i}>
            {part && LINK_REGEX.test(part) ? (
              <React.Fragment>
                {index > 0 && linkSectionIndex++ === 0 && (<span><br />{`Links:- `}<br /></span>)}
                <a href={part} target="_blank" rel="noopener noreferrer" style={{ color: '#4800FF' }}>
                  {part}
                </a>
                {index > 0 && (<br />)}
              </React.Fragment>
            ) : <span>{part}</span>}
          </React.Fragment>
        ))}
      </div>
    );
  });
};

export const sortPlansAndOptions = (data, selectedKey) => {
  return data.map((plan) => {
    plan.options = _.sortBy(plan.options, [
      (option) => (option.key === selectedKey ? -1 : 1),
    ]);
    return plan;
  })
};

export const showSuccessMessage = (messageContent, duration = 2) => {
  message.success({
    className: "ProfileView_message",
    content: messageContent,
    icon: <CheckCircleFilled />,
    duration: duration,
  });
};

export const showErrorMessage = (messageContent, duration = 2) => {
  message.error({
    className: "ProfileView_message",
    content: messageContent,
    icon: <ExclamationCircleFilled />,
    duration: duration,
  });
};

export const showWarningMessage = (messageContent, duration = 2) => {
  message.warning({
    className: "ProfileView_message",
    content: messageContent,
    icon: <ExclamationCircleFilled />,
    duration: duration,
  });
};

export function showLoader(spinContent) {
  return (
    <div className='BulkUpload_loading'>
      <div className='custom-spin'>
        <LoadingOutlined />
        <span>{spinContent}</span>
      </div>
    </div>
  );
}

export const formatToCustomTime = (time) => {
  return moment(time).format('ddd, D MMM YY, h:mm A')
}

export const getTextValue = (value, options) => {
  const matchedOption = options.find((opt) => opt.value === value);
  return matchedOption?.label;
}

export const getLabelFromValue = (value, options) => {
  const matchedOption = _.find(options, { value })
  return matchedOption?.label || value;
};


export const checkEmptyFields = (formTemplateData, disabledFields = {}, disabledSections = {}) => {
  for (const fieldKey in formTemplateData) {
    const fieldData = formTemplateData[fieldKey];
    const fieldValue = fieldData['fieldValue'];
    const isFieldMandatory = fieldData.fieldStyle?.showMandatoryMarker;
    const isFieldDisabled = disabledFields[fieldKey] == undefined ? fieldData.fieldStyle?.disabled : disabledFields[fieldKey];
    const isFieldHidden = disabledSections[fieldKey] == undefined ? fieldData.fieldStyle?.hide : disabledSections[fieldKey];
    const isFieldFilled = fieldValue !== undefined && fieldValue.trim() !== '';
    if (isFieldMandatory && !isFieldFilled && !isFieldDisabled && !isFieldHidden) {
      return true;
    }
  }

  return false;
};

export const checkRegexInValidation = (formTemplateData, disabledFields = {}, disabledSections = {}) => {
  for (const fieldKey in formTemplateData) {
    const fieldData = formTemplateData[fieldKey];
    const fieldValue = fieldData['fieldValue'];
    const isFieldDisabled = disabledFields[fieldKey] == undefined ? fieldData.fieldStyle?.disabled : disabledFields[fieldKey];
    const isFieldHidden = disabledSections[fieldKey] == undefined ? fieldData.fieldStyle?.hide : disabledSections[fieldKey];
    if (fieldData.fieldValidator && fieldValue && !isFieldDisabled && !isFieldHidden) {
      const regex = new RegExp(fieldData.fieldValidator);
      if (!regex.test(fieldValue)) {
        return true;
      }
    }
  }
  return false;
};

export const formatCleanData = (data) => {
  if (data === undefined || data === null || data.trim() === '') {
    return ""
  }
  let cleanedData = data.trim();
  cleanedData = cleanedData.replace(/[\r\n]+/g, '').replace(/\s+/g, ' ');
  return cleanedData;
};


export const getStatusStyle = (status) => {
  switch (status) {
    case "pending":
      return { background: "#FAAD14" };
    case "active":
      return { background: "#52C41A" };
    case "inactive":
      return { background: "#FF4D4F" };
    case "completed":
      return { background: "#1890FF" };
    case "approved":
      return { background: "#52C41A" };
    case "rejected":
      return { background: "#FF4D4F" };
    case "draft":
      return { background: "red" };
    case "ready":
      return { background: "grey" }
    default:
      return {};
  }
};

export const getStatusText = (status) => {
  switch (status) {
    case "pending":
      return { color: "#FAAD14" };
    case "rejected":
      return { color: "#FF4D4F" };
    case "accepted":
      return { color: "#EB2F96" };
    case "approved":
      return { color: "#52C41A" };
    default:
      return {};
  }
};

export const capitalizeFirstLetter = (str) => {
  if (!str || typeof str !== 'string') {
    return '';
  }
  return str?.charAt(0).toUpperCase() + str?.slice(1);
};

export const getYoutubeEmbedUrl = (url) => {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);

  const id = (match && match[2].length === 11)
    ? match[2]
    : null;
  
  return id !== null ? `https://www.youtube.com/embed/${id}?autoplay=1&cc_load_policy=1&controls=0` : null;
}